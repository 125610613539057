// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import type { StateCreator, StoreMutatorIdentifier, State } from 'zustand';

type Logger = <
  T extends State,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = [],
>(
  f: StateCreator<T, Mps, Mcs>,
  name?: string
) => StateCreator<T, Mps, Mcs>;

type LoggerImpl = <T extends State>(
  f: StateCreator<T>,
  name?: string
) => StateCreator<T>;

const loggerImpl: LoggerImpl = (f, name) => (set, get, store) => {
  // type T = ReturnType<typeof f>
  const loggedSet: typeof set = (...a) => {
    set(...a);

    if (process.env.NODE_ENV !== 'production') {
      console.log(...(name ? [`${name}:`] : []), get());
    }
  };
  store.setState = loggedSet;

  return f(loggedSet, get, store);
};

export const logger = loggerImpl as unknown as Logger;
