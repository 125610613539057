import {
  type AxiosRequestConfig,
  publicHttpClient,
} from '@ahm/api-wrappers-core';
import { apiRoutes } from '../api-routes';

export interface GetDealResponce {
  tags: Tag[];
}

export interface Tag {
  _id: number;
  icon_image_url: string;
  enable: boolean;
  order: number;
  title_vi_vn: string;
  title_en_us: string;
  description_vi_vn: string;
  description_en_us: string;
  type: string;
  screen: string[];
  color: string;
  update_at: number;
  create_at: number;
  tag: string;
  store_ids: number[];
}

export interface GetDealParams {
  skip: number;
  limit: number;
  city_id: string;
}

export const getDealFn = async (
  params: GetDealParams,
  config: AxiosRequestConfig = {}
) => {
  const url = apiRoutes.ahafood.store.get_deal;
  const response = await publicHttpClient.get<GetDealResponce>(url, {
    params,
    ...config,
  });

  return response?.data;
};
