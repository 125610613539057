import { useLocale } from 'next-intl';
import { createNavigation } from 'next-intl/navigation';
import { defineRouting } from 'next-intl/routing';

export const locales = ['vi', 'en'];

export const defaultLocale = 'vi';

export const localePrefix = 'as-needed';

export const routing = defineRouting({
  // A list of all locales that are supported
  locales,

  // Used when no locale matches
  defaultLocale,

  // Use locale can without a prefix
  localePrefix,

  localeDetection: false,
});

// Lightweight wrappers around Next.js' navigation APIs
// that will consider the routing configuration
export const { Link, redirect, usePathname, useRouter } =
  createNavigation(routing);

export const useLocaleSuffix = () => {
  const curLocale = useLocale();
  return curLocale === 'en' ? 'en_us' : 'vi_vn';
};
