import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import {
  type GetDealParams,
  type GetDealResponce,
  getDealFn,
} from './ahafood/get-deal-fn';

export const useGetDeal = (
  params: GetDealParams,
  opts?: Omit<UseQueryOptions<GetDealResponce, Error>, 'queryKey' | 'queryFn'>
) => {
  return useQuery<GetDealResponce, Error>({
    queryKey: ['foodOrderDeal', params],
    queryFn: () => getDealFn(params),
    ...opts,
  });
};
